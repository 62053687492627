<template>
  <div>
    <h1>
      De Slimme Wijken Rekenhulp: <br />
      vertrekpunt voor de verbetering van uw woning
    </h1>
    <p>
      Klik op de knop die past bij uw woningtype en bouwperiode en maak kennis
      met het Slimme Wijken Advies voor uw woning. Met dit advies bespaart u de
      meeste energie en krijgt u meer comfort. U krijgt inzicht in de te nemen
      maatregelen, de investering en in wat het u oplevert. Gebruik de rekenhulp
      om dit advies aan te passen aan uw situatie en wensen. Zo maakt u er uw
      eigen verbeterplan van.
    </p>
    <div class="package-grid">
      <PackageItem
        v-for="(measure, index) in measures"
        :placeholder="measure.placeholder"
        :packageTitle="measure.title"
        :packageSubtitle="measure.buildyear"
        :image="measure.image"
        :key="index"
        @click.native="setActivePackage(measure)"
      />
    </div>
  </div>
</template>

<script>
import cssVars from "css-vars-ponyfill";

export default {
  components: {
    PackageItem: () => import("../components/PackageItem"),
  },
  data() {
    return {
      measures: [
        { placeholder: true },
        {
          placeholder: false,
          title: "Vrijstaande woning",
          buildyear: "voor 1965",
          maxHeatloss: "79,2",
          image: require("@/assets/images/01_vrijstaande_woning_voor_1965.svg"),
        },
        {
          placeholder: false,
          title: "Vrijstaande woning",
          buildyear: "1965-1974",
          maxHeatloss: "76,2",
          image: require("@/assets/images/02_vrijstaande_woning_1965-1974.svg"),
        },
        {
          placeholder: false,
          title: "Vrijstaande woning",
          buildyear: "1975-1991",
          maxHeatloss: "79,2",
          image: require("@/assets/images/03_vrijstaande_woning_1975-1991.svg"),
        },
        {
          placeholder: false,
          title: "Vrijstaande woning",
          buildyear: "vanaf 1991",
          maxHeatloss: "81,5",
          image: require("@/assets/images/04_vrijstaande_woning_na_1991.svg"),
        },
        {
          placeholder: true,
        },
        {
          placeholder: false,
          title: "Twee onder een kap",
          buildyear: "voor 1965",
          maxHeatloss: "74,5",
          image: require("@/assets/images/05_twee_onder_een_kap_voor_1965.svg"),
        },
        {
          placeholder: false,
          title: "Twee onder een kap",
          buildyear: "1965-1974",
          maxHeatloss: "73,3",
          image: require("@/assets/images/06_twee_onder_een_kap_1965-1974.svg"),
        },
        {
          placeholder: false,
          title: "Twee onder een kap",
          buildyear: "1975-1991",
          maxHeatloss: "73,8",
          image: require("@/assets/images/07_twee_onder_een_kap_1975-1991.svg"),
        },
        {
          placeholder: false,
          title: "Twee onder een kap",
          buildyear: "vanaf 1991",
          maxHeatloss: "76,1",
          image: require("@/assets/images/08_twee_onder_een_kap_na_1991.svg"),
        },
        {
          placeholder: false,
          title: "Rijwoning",
          buildyear: "voor 1945",
          maxHeatloss: "133,2",
          image: require("@/assets/images/09_rijwoning_voor_1945.svg"),
        },
        {
          placeholder: false,
          title: "Rijwoning",
          buildyear: "1945-1965",
          maxHeatloss: "60,5",
          image: require("@/assets/images/10_rijwoning_1945-1965.svg"),
        },
        {
          placeholder: false,
          title: "Rijwoning",
          buildyear: "1965-1974",
          maxHeatloss: "63,9",
          image: require("@/assets/images/11_rijwoning_1965-1974.svg"),
        },
        {
          placeholder: false,
          title: "Rijwoning",
          buildyear: "1975-1991",
          maxHeatloss: "63,7",
          image: require("@/assets/images/12_rijwoning_1975-1991.svg"),
        },
        {
          placeholder: false,
          title: "Rijwoning",
          buildyear: "vanaf 1991",
          maxHeatloss: "65,7",
          image: require("@/assets/images/13_rijwoning_na_1991.svg"),
        },
        {
          placeholder: true,
        },
        {
          placeholder: false,
          title: "Maisonnette",
          buildyear: "voor 1965",
          maxHeatloss: "72,7",
          image: require("@/assets/images/14_maisonette_voor_1965.svg"),
        },
        {
          placeholder: false,
          title: "Maisonnette",
          buildyear: "1965-1974",
          maxHeatloss: "60,9",
          image: require("@/assets/images/15_maisonette_1965-1974.svg"),
        },
        {
          placeholder: false,
          title: "Maisonnette",
          buildyear: "1975-1991",
          maxHeatloss: "67,0",
          image: require("@/assets/images/16_maisonette_1975-1991.svg"),
        },
        {
          placeholder: false,
          title: "Maisonnette",
          buildyear: "vanaf 1991",
          maxHeatloss: "65,8",
          image: require("@/assets/images/17_maisonette_na_1991.svg"),
        },
      ],
    };
  },
  created() {
    cssVars();
  },
  methods: {
    setActivePackage(item) {
      // Set the selected measure package as active in app state
      this.$store.commit("setActivePackage", item);
      this.$router.push({
        path: "/rekenmodule",
        query: {
          houseType: item.title.replace(/ /g, "_"),
          buildyear: item.buildyear.replace(/ /g, "_"),
          maxHeatloss: item.maxHeatloss.replace(/ /g, "_"),
        },
      });
    },
  },
};
</script>

<style lang="scss">
.package-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-gap: 8px;
  margin-top: 2em;
  // for ie
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    flex-wrap: wrap;

    .placeholder-package-item {
      height: 100%;
      flex: 1 1 180px;
      margin: 4px;
    }

    .package-item {
      flex: 1 1 180px;
      margin: 4px;
    }
  }
}

@media screen and (max-width: 442px) {
  .placeholder-package-item {
    display: none;
  }
}
</style>
